<template>
  <b-table
    :fields="fields"
    :items="items"
    :busy="busy"
    :sticky-header="stickyHeader"
    :responsive="true"
    :bordered="bordered"
    :borderless="borderless"
    :striped="striped"
    :outlined="outlined"
    :small="small"
    :hover="hover"
    :dark="dark"
    :fixed="fixed"
    :foot-clone="footClone"
    :no-border-collapse="noCollapse"
    :empty-text="$t('general.noRecord')"
    :empty-filtered-text="$t('general.noRecord')"
    :no-local-sorting="true"
    show-empty
    @sort-changed="sortChanged"
  >
    <template #cell(increasement)="data">
      {{ calculateOrderNumber(data.index) }}
    </template>
    <!-- <template #table-busy>
      <div class="text-center my-1">
        <b-skeleton-table
          :rows="10"
          :columns="fields.length"
          :hide-header="true"
          :table-props="{ bordered: false, striped: true }"
        ></b-skeleton-table>
      </div>
    </template> -->
    <template #table-busy>
      <div class="text-center my-1">
        <b-spinner label="Spinning" />
      </div>
    </template>

    <template #head()="scope">
      <div class="text-nowrap">
        {{ scope.field.noTranslateLabel ? scope.label : $t(scope.label) }}
      </div>
    </template>

    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </b-table>
</template>

<script>
import { BSpinner, BTable } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BTable,
    BSpinner,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default() {
        return this.$store.state.pagination.perPage
      },
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    calculateOrderNumber(index) {
      const from = (this.currentPage - 1) * this.perPage + 1
      return from + index
    },
    submit() {
      this.$emit('submit')
    },
    sortChanged(ctx) {
      const item = this.fields.find(value => {
        if (value.key === ctx.sortBy) {
          return value
        }
      })

      if (!item) {
        return
      }

      const sortBy = item?.sortField ? item.sortField : item?.key
      const sortDirection = ctx.sortDesc ? 'desc' : 'asc'
      this.$emit('sort-changed', sortBy, sortDirection)
    },
  },
  setup() {
    // App Name
    const {
      bordered,
      borderless,
      striped,
      outlined,
      small,
      hover,
      dark,
      fixed,
      footClone,
      noCollapse,
    } = $themeConfig.table
    return {
      bordered,
      borderless,
      striped,
      outlined,
      small,
      hover,
      dark,
      fixed,
      footClone,
      noCollapse,
    }
  },
}
</script>

<style scoped>
</style>
